import { useStyles } from './Image.style';

export const Fallback = (): React.ReactElement => {
  const { classes } = useStyles({});

  return (
    <div className={classes.fallback}>
      <img
        className={classes.fallbackLogo}
        src="/content/assets/images/mydeal_weblogo.svg"
        width={171}
        height={38}
      />
      <p className={classes.fallbackText}>Need it want it, MyDeal it</p>
    </div>
  );
};
