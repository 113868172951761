import { createStyles } from '@mantine/core';

interface StyleProps {
  width: number;
  height: number;
}

export const useStyles = createStyles((theme, { width, height }: StyleProps) => ({
  image: {
    position: 'relative',
    maxWidth: '100%',
    height: 'auto'
  },

  fallbackWrapper: {
    position: 'relative',
    paddingBottom: `${(height / width) * 100}%`
  },

  fallback: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFC8CC'
  },

  fallbackLogo: {
    maxWidth: '30%'
  },

  fallbackText: {
    color: theme.colors.orange[5],
    margin: 0
  }
}));
