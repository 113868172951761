import { MantineProvider } from '@mantine/core';
import { CookiesProvider } from 'react-cookie';
import { Helmet } from 'react-helmet';

import { theme } from './mantineTheme';
import { ScrollProvider } from './ScrollProvider';
import { WishlistProvider } from './WishlistProvider';

import { mydealCache } from '@/services/styleCache';

export interface PartialWrapperProps {
  children: React.ReactElement;
  initialWishlistCount?: number;
}

export const PartialWrapper = ({
  children,
  initialWishlistCount
}: PartialWrapperProps): React.ReactElement => {
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="/content/assets/fonts.css" />
      </Helmet>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        emotionCache={mydealCache}
        theme={theme}
      >
        <CookiesProvider>
          <ScrollProvider>
            <WishlistProvider initialCount={initialWishlistCount}>
              {children}
            </WishlistProvider>
          </ScrollProvider>
        </CookiesProvider>
      </MantineProvider>
    </>
  );
};
