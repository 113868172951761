import { createStyles, getStylesRef } from '@mantine/core';

import { type ControlsVariant } from './Carousel.types';

interface StyleProps {
  alwaysShowControls?: boolean;
  controlsVariant: ControlsVariant;
  horizontalViewportPadding?: boolean;
}

export const useStyles = createStyles((theme, {
  alwaysShowControls,
  controlsVariant,
  horizontalViewportPadding
}: StyleProps) => ({
  controls: {
    top: 'calc(50% - 0.9375rem)',
    transform: 'translateY(-50%)',
    ref: getStylesRef('controls'),
    transition: 'opacity 150ms ease-in-out',
    opacity: alwaysShowControls ? 1 : 0,

    '@media (max-width: 1024px) and (min-width: 520px)': {
      opacity: 1
    }
  },

  control: {
    backgroundColor: controlsVariant === 'light' ? 'rgba(255,255,255,0.65)' : theme.colors.orange[5],
    border: 'none',

    '&[data-inactive]': {
      opacity: 0,
      cursor: 'default'
    }
  },

  root: {
    width: '100%',
    '&:hover': {
      [`& .${getStylesRef('controls')}`]: {
        opacity: 1
      }
    }
  },

  indicators: {
    position: 'initial',
    marginTop: '1.5rem',
    flexWrap: 'wrap'
  },

  indicator: {
    width: '6px',
    height: '6px',
    background: '#000000',
    opacity: 0.2,
    transition: 'width 250ms ease-in-out',

    '&[data-active]': {
      width: '28px',
      backgroundColor: '#F04A1C',
      opacity: 1
    }
  },

  slide: {
    minWidth: 0
  },

  // necessary otherwise box-shadow hover effects get clipped
  viewport: {
    padding: horizontalViewportPadding ? '0 10px 10px 10px' : '0 0 10px 0',
    marginBottom: '-10px'
  }
}));
