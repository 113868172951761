import { createContext } from 'react';

import { useScrollListener, type ScrollListener } from '@/hooks/useScrollListener';

interface ScrollProviderProps {
  children: React.ReactElement;
}

export const ScrollContext = createContext<ScrollListener>({
  x: 0,
  y: 0
});

export const ScrollProvider = ({ children }: ScrollProviderProps): React.ReactElement => {
  const scrollPosition = useScrollListener();

  return (
    <ScrollContext.Provider value={scrollPosition}>
      {children}
    </ScrollContext.Provider>
  );
};
