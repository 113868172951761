import { createContext, useReducer } from 'react';

import { useCookies } from 'react-cookie';

enum WishlistActionTypes {
  INCREMENT_WISHLIST = 'INCREMENT_WISHLIST',
  DECREMENT_WISHLIST = 'DECREMENT_WISHLIST'
}

interface IncrementWishlistAction {
  type: WishlistActionTypes.INCREMENT_WISHLIST;
}

interface DecrementWishlistAction {
  type: WishlistActionTypes.DECREMENT_WISHLIST;
}

type WishlistAction = IncrementWishlistAction | DecrementWishlistAction;

interface WishlistState {
  count: number;
}

const wishlistReducer = (state: WishlistState, action: WishlistAction): WishlistState => {
  switch (action.type) {
    case WishlistActionTypes.INCREMENT_WISHLIST:
      return { ...state, count: state.count + 1 };
    case WishlistActionTypes.DECREMENT_WISHLIST:
      return { ...state, count: Math.max(0, state.count - 1) };
    default:
      return state;
  }
};

export interface WishlistContextProps {
  count: number;
  incrementWishlist: () => void;
  decrementWishlist: () => void;
}

export const WishlistContext = createContext<WishlistContextProps | undefined>(undefined);

interface WishlistProviderProps {
  children: React.ReactNode;
  initialCount?: number;
}

export const WishlistProvider = ({ children, initialCount }: WishlistProviderProps): React.ReactElement => {
  const [cookies] = useCookies(['wish']);
  const cookie = cookies.wish as Record<string, string>;
  const countInCookies = cookie ? Object.keys(cookie).length : 0;

  const [state, dispatch] = useReducer(wishlistReducer, {
    count: initialCount ?? countInCookies
  });

  const incrementWishlist = (): void => dispatch({ type: WishlistActionTypes.INCREMENT_WISHLIST });
  const decrementWishlist = (): void => dispatch({ type: WishlistActionTypes.DECREMENT_WISHLIST });

  return (
    <WishlistContext.Provider value={{ count: state.count, incrementWishlist, decrementWishlist }}>
      {children}
    </WishlistContext.Provider>
  );
};
