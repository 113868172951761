import { createStyles } from '@mantine/core';

export const useStyles = createStyles(theme => ({
  heroBannerWrapper: {
    marginBottom: '2rem'
  },

  hideOnMobile: {
    display: 'none',

    [theme.fn.largerThan('xs')]: {
      display: 'block'
    }
  },

  hideOnDesktop: {
    [theme.fn.largerThan('xs')]: {
      display: 'none'
    }
  }
}));
