import { type GtmDatalayer } from '@/types/gtmDatalayer';

export const generateUrlWithQueryParams = (path: string, params: Record<string, string>): string => {
  let paramsString = '';
  let counter = 0;

  for (const i in params) {
    if (counter > 0) {
      paramsString += '&';
    }

    paramsString += `${i}=${params[i]}`;
    counter++;
  }

  return `${path}?${paramsString}`;
};

/*
 * We had to implement our own version of this instead of using new URL() and url.searchParams.set()
 * Because the URL object is undefined when we import our components into .NET.
 * Probably has to do with Node.js version used by ReactJS.NET
 */
export const addQueryParams = (url: string, params: Record<string, string>): string => {
  let result = url;

  result += result.includes('?') ? '&' : '?';

  for (const i in params) {
    result += `${i}=${params[i]}&`;
  }

  return result.slice(0, -1);
};

export const pushToGtmDatalayer = (items?: GtmDatalayer): void => {
  if (typeof window === 'undefined') return;

  if (!window.dataLayer) {
    window.dataLayer = [];
  }

  if (items) {
    window.dataLayer.push(items);
  }
};

export const getQueryParameter = (name: string): string | null => {
  const urlParams = new URLSearchParams(window.location.search);

  return urlParams.get(name);
};
