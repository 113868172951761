import { useState } from 'react';

import { Fallback } from './Fallback';
import { useStyles } from './Image.style';

import { addQueryParams } from '@/lib/utils';

export interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  className?: string;
  disableLazyLoad?: boolean;
  width: number;
  height: number;
  src: string;
  fit?: 'bounds' | 'cover' | 'crop';
};

export const Image = ({
  className,
  disableLazyLoad,
  width,
  height,
  src,
  fit,
  ...props
}: ImageProps): React.ReactElement => {
  const { classes, cx } = useStyles({ width, height });
  const [hasFailed, setHasFailed] = useState(false);

  const handleError = (): void => setHasFailed(true);

  const url = addQueryParams(src, {
    width: width.toString(),
    height: height.toString(),
    fit: fit ?? 'bounds',
    canvas: `${width},${height}`
  });

  if (hasFailed) {
    return (
      <div className={classes.fallbackWrapper}>
        <Fallback />
      </div>
    );
  }

  return (
    <img
      loading={disableLazyLoad ? 'eager' : 'lazy'}
      className={cx(className, classes.image)}
      onError={handleError}
      width={width}
      height={height}
      src={url}
      {...props}
    />
  );
};
