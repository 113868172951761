import { useEffect, useState, useRef } from 'react';

import { Carousel as MantineCarousel, type Embla } from '@mantine/carousel';
import { type CarouselBreakpoint } from '@mantine/carousel/lib/types';
import { type MantineNumberSize, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import Autoplay from 'embla-carousel-autoplay';

import { useStyles } from './Carousel.style';
import { type ControlsVariant } from './Carousel.types';

export interface CarouselProps {
  autoplay?: boolean;
  alwaysShowControls?: boolean;
  breakpoints?: CarouselBreakpoint[];
  controlsVariant: ControlsVariant;
  children?: React.ReactNode;
  loop?: boolean;
  slideSize?: string | number;
  slideGap?: MantineNumberSize;
  slidesToScroll?: number | 'auto';
  horizontalViewportPadding?: boolean;
  slidesAmount?: number;
}

export const Carousel = ({
  autoplay,
  alwaysShowControls,
  breakpoints,
  children,
  controlsVariant,
  horizontalViewportPadding,
  loop,
  slideSize,
  slideGap,
  slidesToScroll,
  slidesAmount
}: CarouselProps): React.ReactElement => {
  const theme = useMantineTheme();
  const { classes } = useStyles({
    alwaysShowControls,
    controlsVariant,
    horizontalViewportPadding
  });
  const iconColor = controlsVariant === 'light' ? theme.colors.orange[5] : 'white';
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const autoplayPlugin = useRef(Autoplay({ delay: 5000 }));
  const handleMouseEnter = (): void => autoplay ? autoplayPlugin.current.stop() : undefined;
  const handleMouseLeave = (): void => autoplay ? autoplayPlugin.current.play(false) : undefined;

  const [embla, setEmbla] = useState<Embla | null>(null);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleResize = (): void => {
    const slidesNotInView = embla?.slidesNotInView();

    if (!slidesNotInView || slidesNotInView.length === 0) {
      setIsDisabled(true);
    }
    else {
      setIsDisabled(false);
    }
  };

  useEffect(() => {
    if (embla) {
      embla.on('resize', handleResize);
      handleResize();
    }
  }, [embla]);

  return (
    <MantineCarousel
      key={slidesAmount}
      align="start"
      breakpoints={breakpoints}
      classNames={classes}
      controlSize={50}
      draggable={!isDisabled}
      getEmblaApi={setEmbla}
      loop={loop}
      mx="auto"
      nextControlIcon={<IconChevronRight color={iconColor} size={32} />}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      plugins={autoplay ? [autoplayPlugin.current] : undefined}
      previousControlIcon={<IconChevronLeft color={iconColor} size={32} />}
      slideGap={slideGap}
      slideSize={slideSize}
      slidesToScroll={slidesToScroll}
      withIndicators={!isDisabled}
      withControls={!mobile && !isDisabled}
    >
      {children}
    </MantineCarousel>
  );
};

export const Slide = MantineCarousel.Slide;
