import { useStyles } from './HeroBanner.style';
import { type HeroBannerProps } from './HeroBanner.types';

import { Carousel, Slide } from '@/components/Carousel';
import { Image } from '@/components/Image';
import { PartialWrapper } from '@/components/PartialWrapper';

export const HeroBanner = ({ slides, enableLazyLoad }: HeroBannerProps): React.ReactElement => {
  const { classes } = useStyles();

  return (
    <section id="main-homepage-slider" className={classes.heroBannerWrapper}>
      <Carousel
        autoplay
        alwaysShowControls
        controlsVariant="light"
        loop={true}
      >
        {slides.map((slide, index) => (
          <Slide key={index}>
            <a href={slide.href}>
              <span className={classes.hideOnDesktop}>
                <Image
                  alt={slide.alt}
                  disableLazyLoad={!enableLazyLoad}
                  src={slide.mobileImage}
                  width={686}
                  height={429}
                />
              </span>
              <span className={classes.hideOnMobile}>
                <Image
                  alt={slide.alt}
                  disableLazyLoad={!enableLazyLoad}
                  src={slide.desktopImage}
                  width={1366}
                  height={300}
                />
              </span>
            </a>
          </Slide>
        ))}
      </Carousel>
    </section>
  );
};

export const HeroBannerWrapped = (props: HeroBannerProps): React.ReactElement => (
  <PartialWrapper>
    <HeroBanner {...props} />
  </PartialWrapper>
);
