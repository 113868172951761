import { useEffect, useState } from 'react';

export interface ScrollListener {
  x: number;
  y: number;
}

export const useScrollListener = (): ScrollListener => {
  const [scrollPosition, setScrollPosition] = useState<ScrollListener>({
    x: 0,
    y: 0
  });

  const handleScroll = (): void => {
    setScrollPosition({
      x: typeof window === 'undefined' ? 0 : window.scrollX || window.pageXOffset,
      y: typeof window === 'undefined' ? 0 : window.scrollY || window.pageYOffset
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return scrollPosition;
};
